<template>
  <v-col
    sm="12"
    lg="2"
    :class="{
      'px-0': mobile
    }"
  >
    <v-select
      color="teal"
      item-color="teal"
      :value="params.transitProcedureCondition"
      :items="conditions"
      hide-details="auto"
      background-color="white"
      class="ba-0 rounded"
      filled
      rounded
      label="Процедура"
      @change="setCondition"
    />
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex'

const TYPE = 'td'

export default {
  data:() => ({
    params:{
      transitProcedureCondition:null
    },
    conditions:[
      {
        text:'ВСЕ ТИПЫ',
        value:null
      },
      {
        text:'ИМ',
        value:'ИМ'
      },
      {
        text:'ЭК',
        value:'ЭК'
      },
      {
        text:'ТР',
        value:'ТР'
      },
      {
        text:'ВТ',
        value:'ВТ'
      },
      {
        text:'ТС',
        value:'ТС'
      }
    ]
  }),  
  computed:{
    ...mapGetters({
      typeDependentParams:'journal/getTypeDependentParams'
    }),
    mobile(){
      return this.$vuetify.breakpoint.mobile
    }
  },
  watch:{
    typeDependentParams:{
      handler(){
        this.setValues()
      },
      deep:true,
      immediate:true
    }
  },
  methods:{
    setValues(){
      const available = this.typeDependentParams.filter(i => i.type === TYPE) 
      available.forEach(item => {
        const {name, value} = item
        if(name in this.params){
          this.params[name] = value
        }
      })
    },
    setCondition(value){
      this.$store.commit('journal/SET_TYPE_DEPENDENT_PARAM', {
        type:TYPE,
        name:'transitProcedureCondition',
        value
      })
      this.$store.dispatch('journal/fetchItems')
    }
  }
}
</script>